<template>
  <div>

    <div v-if="menuState === ''">
      <div v-if="userDetails">
        <!-- <button @click="logout">Logout</button>
        <h2>User Details</h2>
        <p>Name: {{ userDetails.name }}</p>
        <p>Email: {{ userDetails.email }}</p>
        <p>Profile Picture: <img :src="userDetails.picture" alt="Profile Picture"></p> -->
      </div>
      <!-- <button v-else @click="login">Login Using Google</button> -->
      <div v-else class="bg-gray-900 flex items-center justify-center min-h-screen">
        <button
          class="rounded-full w-64 h-12 mx-auto text-white border-2 border-green-500 flex items-center justify-center"
          @click="login()">
          <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            width="700px" height="700px" viewBox="-0.5 0 48 48" version="1.1">
            <title>Google-color</title>
            <desc>Created with Sketch.</desc>
            <defs> </defs>
            <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Color-" transform="translate(-401.000000, -860.000000)">
                <g id="Google" transform="translate(401.000000, 860.000000)">
                  <path
                    d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                    id="Fill-1" fill="#FBBC05"> </path>
                  <path
                    d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                    id="Fill-2" fill="#EB4335"> </path>
                  <path
                    d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                    id="Fill-3" fill="#34A853"> </path>
                  <path
                    d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                    id="Fill-4" fill="#4285F4"> </path>
                </g>
              </g>
            </g>
          </svg>
          <span>Continue with Google</span>
        </button>

      </div>
    </div>
    <!--  -->
    <div v-else-if="menuState === 'connect'">
      <div class="bg-gray-900 flex items-center justify-center min-h-screen">
        <div class="text-center my-4">
          <!-- <div class="flex mb-4 items-center justify-center">
            <img :src="userDetails.picture" alt="Profile Picture" class="w-8 h-8 rounded-full mr-2">
            <div class="text-white text-sm">{{ userDetails.name }}</div>
            <button @click="logout()" class="text-white text-sm">Logout</button>
          </div> -->
          <div class="mb-4">
            <div
              class="flex items-center justify-center bg-gray-800 border-2 border-blue-500 rounded-lg w-64 h-16 mx-auto">
              <div class="w-10 h-10 flex items-center justify-center mr-4 pl-4">
                <font-awesome-icon :icon="['fas', 'plug']" class="text-green-500 text-2xl" />
              </div>
              <input type="number" placeholder="Input Code"
                class="rounded-lg bg-gray-800 text-white placeholder-gray-400 text-xl w-full h-full pr-4 focus:outline-none"
                v-model="connectData.code">
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 place-items-center">
            <button @click="handleNumberClick('1')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">1</button>
            <button @click="handleNumberClick('2')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">2</button>
            <button @click="handleNumberClick('3')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">3</button>
            <button @click="handleNumberClick('4')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">4</button>
            <button @click="handleNumberClick('5')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">5</button>
            <button @click="handleNumberClick('6')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">6</button>
            <button @click="handleNumberClick('7')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">7</button>
            <button @click="handleNumberClick('8')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">8</button>
            <button @click="handleNumberClick('9')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">9</button>
            <button @click="handleDelete()"
              class="bg-gray-800 text-red-500 text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">
              <font-awesome-icon :icon="['fas', 'times']" />
            </button>
            <button @click="handleNumberClick('0')"
              class="bg-gray-800 text-white text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">0</button>
            <button @click="handleSubmit()"
              class="bg-gray-800 text-green-500 text-2xl w-16 h-16 rounded-full shadow-lg flex items-center justify-center">
              <font-awesome-icon :icon="['fas', 'check']" />
            </button>
          </div>
          <div class="mt-10 mb-3 text-white text-sm">Don't have any code to connect?</div>
          <button class="rounded-full w-64 h-12 mx-auto text-white border-2 border-green-500"
            @click="handleSubmit()">Create Room
          </button>
        </div>
      </div>
      <!-- left arrow -->
      <button class="fixed top-4 left-4 p-2 rounded-full" @click="logout()">
        <font-awesome-icon :icon="['fas', 'chevron-left']" class="w-6 h-6 text-white" />
      </button>
    </div>
    <!--  -->
    <div v-else-if="menuState === 'play'">
      <div class="bg-gray-800 text-white">
        <div class="flex flex-col items-center justify-center h-screen overflow-hidden">
          <!-- Main Content -->
          <div class="relative flex-grow flex items-center justify-center pb-24">
            <div
              class="relative w-full md:w-3/4 h-3/4 bg-gray-700 rounded-lg overflow-hidden flex items-center justify-center">
              <img alt="Among Us game screenshot" class="w-full h-full object-cover" height="600"
                src="https://storage.googleapis.com/a1aa/image/dsJMK1Rx2fSRZSY49Q6wFllBM2IKjhP6AQEzwfENdCqm1fSnA.jpg"
                width="800" />
              <div class="absolute top-0 left-0 p-2">
                <div class="bg-gray-900 text-white px-2 py-1 rounded">
                  Blue Lock Game
                </div>
              </div>
            </div>
          </div>
          <!-- Bottom Bar -->
          <div
            class="sticky-bottom-bar w-full flex flex-col md:flex-row items-center justify-center p-2 bg-gray-900 overflow-hidden md:h-24">
            <div v-if="playData.buttons[0].isActive" class="swiper-container overflow-y-auto mb-2 md:mb-0 custom-scroll">
              <div class="swiper-wrapper items-center">
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 1" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/7zAwyVvtrF6FJN8lfSXQn8iPBOMsCSzA4dXyFpiQwqxv6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-pink-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 2" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/ZIfeNdZkB7rs5EZdh7c6n02cH5rrKx5n0ijKoilhAo3i1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-orange-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 3" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KGn8VI8LKZ4lAZqJ2eUwY1488DmrWcucHjwgBIR81Bvy6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-purple-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 4" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/DDO8wFTC7I5pPNXXaUq73fnhSw7ZZZaGgZbgeUJppBio1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-yellow-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 5" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/yZZkAeWVeXoV7UDZVyITPq8tpw7HTPaWwBm7KYEGwYKp1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 6" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KrYzaP9XUMq5I5OeSB7yydSrZAgKOLIYuzitf66jfkI7qflOB.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-green-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 7" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/vGdJMOoTCz45NxfrQY5G7IOto0DSatQ6lF3mILp3RVby6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-indigo-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 8" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/Tc4xgwymfbXFBSLmdcEil9yNOdVscoI3RvcmGesgeaoBrflOB.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 1" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/7zAwyVvtrF6FJN8lfSXQn8iPBOMsCSzA4dXyFpiQwqxv6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-pink-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 2" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/ZIfeNdZkB7rs5EZdh7c6n02cH5rrKx5n0ijKoilhAo3i1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-orange-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 3" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KGn8VI8LKZ4lAZqJ2eUwY1488DmrWcucHjwgBIR81Bvy6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-purple-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 4" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/DDO8wFTC7I5pPNXXaUq73fnhSw7ZZZaGgZbgeUJppBio1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-yellow-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 5" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/yZZkAeWVeXoV7UDZVyITPq8tpw7HTPaWwBm7KYEGwYKp1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 6" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KrYzaP9XUMq5I5OeSB7yydSrZAgKOLIYuzitf66jfkI7qflOB.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-green-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 7" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/vGdJMOoTCz45NxfrQY5G7IOto0DSatQ6lF3mILp3RVby6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-indigo-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 8" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/Tc4xgwymfbXFBSLmdcEil9yNOdVscoI3RvcmGesgeaoBrflOB.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 1" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/7zAwyVvtrF6FJN8lfSXQn8iPBOMsCSzA4dXyFpiQwqxv6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-pink-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 2" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/ZIfeNdZkB7rs5EZdh7c6n02cH5rrKx5n0ijKoilhAo3i1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-orange-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 3" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KGn8VI8LKZ4lAZqJ2eUwY1488DmrWcucHjwgBIR81Bvy6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-purple-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 4" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/DDO8wFTC7I5pPNXXaUq73fnhSw7ZZZaGgZbgeUJppBio1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-yellow-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 5" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/yZZkAeWVeXoV7UDZVyITPq8tpw7HTPaWwBm7KYEGwYKp1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 6" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KrYzaP9XUMq5I5OeSB7yydSrZAgKOLIYuzitf66jfkI7qflOB.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-green-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 7" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/vGdJMOoTCz45NxfrQY5G7IOto0DSatQ6lF3mILp3RVby6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-indigo-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 8" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/Tc4xgwymfbXFBSLmdcEil9yNOdVscoI3RvcmGesgeaoBrflOB.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 1" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/7zAwyVvtrF6FJN8lfSXQn8iPBOMsCSzA4dXyFpiQwqxv6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-pink-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 2" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/ZIfeNdZkB7rs5EZdh7c6n02cH5rrKx5n0ijKoilhAo3i1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-orange-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 3" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KGn8VI8LKZ4lAZqJ2eUwY1488DmrWcucHjwgBIR81Bvy6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-purple-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 4" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/DDO8wFTC7I5pPNXXaUq73fnhSw7ZZZaGgZbgeUJppBio1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-yellow-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 5" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/yZZkAeWVeXoV7UDZVyITPq8tpw7HTPaWwBm7KYEGwYKp1fSnA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-blue-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 6" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/KrYzaP9XUMq5I5OeSB7yydSrZAgKOLIYuzitf66jfkI7qflOB.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-green-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 7" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/vGdJMOoTCz45NxfrQY5G7IOto0DSatQ6lF3mILp3RVby6v0JA.jpg"
                    width="50" />
                </div>
                <div class="swiper-slide bg-indigo-500 w-16 h-16 rounded-lg flex items-center justify-center mr-2">
                  <img alt="Avatar 8" class="rounded-full" height="50"
                    src="https://storage.googleapis.com/a1aa/image/Tc4xgwymfbXFBSLmdcEil9yNOdVscoI3RvcmGesgeaoBrflOB.jpg"
                    width="50" />
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center pl-2">
              <button class="bg-gray-900 py-2 px-6 rounded-fulll w-8 h-8 flex items-center justify-center"
                @click="playData.buttons[0].isActive = !playData.buttons[0].isActive">
                <font-awesome-icon :icon="['fas', (playData.buttons[0].isActive ? 'users' : 'users-slash')]" :class="(playData.buttons[0].isActive ? 'text-white' : 'text-gray-600')" />
              </button>
              <button class="bg-gray-900 py-2 px-6 rounded-full w-8 h-8 flex items-center justify-center"
                @click="playData.buttons[1].isActive = !playData.buttons[1].isActive">
                <font-awesome-icon :icon="['fas', (playData.buttons[1].isActive ? 'microphone' : 'microphone-slash')]"  :class="(playData.buttons[1].isActive ? 'text-white' : 'text-gray-600')"/>
              </button>
              <button class="bg-gray-900 py-2 px-6 rounded-fulll w-8 h-8 flex items-center justify-center"
                @click="menuState = 'connect'">
                <font-awesome-icon :icon="['fas', 'phone']" />
              </button>
              <!-- <div class="bg-gray-900 py-2 px-6 rounded-fulll w-8 h-8 flex items-center justify-center">
                <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div v-if="!isFullScreen && menuState != ''">
      <button class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50"
        @click="requestFullScreen()">
        <h2 class="text-2xl font-lg mb-4 text-center text-white"><font-awesome-icon :icon="['fas', 'fingerprint']"
            class="mr-2" /> Tap to fullscreen</h2>
      </button>
    </div>
  </div>
</template>

<script>
import { googleSdkLoaded } from "vue3-google-login";
import Cookies from 'js-cookie';
import axios from "axios";

export default {
  data() {
    return {
      isFullScreen: true,
      userDetails: null,
      menuState: "",//"", connect, play
      connectData: {
        code: ''
      },
      playData: {
        buttons: [
          { isActive: true },
          { isActive: true },
          { isActive: true },
          { isActive: true }
        ]
      }
    };
  },
  mounted() {
    document.title = "Play Together";
    this.menuState = "";
    const userCookie = Cookies.get('user');
    if (userCookie) {
      this.fetchUserData(userCookie);
    }

    if (this.getDeviceType() == "Mobile") {
      this.isFullScreen = false;
    }

    // Menambahkan event listener untuk perubahan fullscreen
    document.addEventListener('fullscreenchange', this.checkFullScreen);
    document.addEventListener('mozfullscreenchange', this.checkFullScreen);
    document.addEventListener('webkitfullscreenchange', this.checkFullScreen);
    document.addEventListener('msfullscreenchange', this.checkFullScreen);
  },
  beforeUnmount() {
    // Menghapus event listener saat komponen dihancurkan
    document.removeEventListener('fullscreenchange', this.checkFullScreen);
    document.removeEventListener('mozfullscreenchange', this.checkFullScreen);
    document.removeEventListener('webkitfullscreenchange', this.checkFullScreen);
    document.removeEventListener('msfullscreenchange', this.checkFullScreen);
  },
  methods: {
    checkFullScreen() {
      if (this.getDeviceType() == "Mobile") {
        this.isFullScreen = !!(document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement);
      }
    },
    login() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id:
              "814080495241-qo6q87dfa2miloa7ac31cd39piq44ijl.apps.googleusercontent.com",
            scope: "email profile openid",
            callback: response => {
              if (response.code) {
                this.sendCodeToBackend(response.code);
              }
            }
          })
          .requestCode();
      });
    },
    logout() {
      Cookies.remove('user');
      this.userDetails = null;
      this.menuState = "";
    }
    , handleNumberClick(number) {
      this.connectData.code += number;
    },
    handleDelete() {
      this.connectData.code = this.connectData.code.slice(0, -1);
    },
    handleSubmit() {
      this.connectSucces();
      //alert(`Code submitted: ${this.connectData.code}`);
    },
    createRoom() {
      this.connectSucces();
      //alert("Creating a new room...");
    }
    ,
    getDeviceType() {
      const userAgent = navigator.userAgent;
      if (/Mobi|Android/i.test(userAgent)) {
        return 'Mobile';
      }
      return 'Desktop';
    },
    requestFullScreen() {
      const element = document.documentElement; // Target the whole document for fullscreen

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen();
      }
    },
    async sendCodeToBackend(code) {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          {
            code,
            client_id:
              "814080495241-qo6q87dfa2miloa7ac31cd39piq44ijl.apps.googleusercontent.com",
            client_secret: "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",
            redirect_uri: "postmessage",
            grant_type: "authorization_code"
          }
        );

        const accessToken = response.data.access_token;
        console.log(accessToken);
        Cookies.set('user', accessToken, { expires: 30 });
        await this.fetchUserData(accessToken);
      } catch (error) {
        //console.error("Token exchange failed:", error.response.data);
      }
    },
    async fetchUserData(accessToken) {
      try {
        // Fetch user details using the access token
        const userResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (userResponse && userResponse.data) {
          // Set the userDetails data property to the userResponse object
          this.userDetails = userResponse.data;
          this.menuState = "connect";
        } else {
          // Handle the case where userResponse or userResponse.data is undefined
          //console.error("Failed to fetch user details.");
          this.logout();
        }
      }
      catch (error) {
        this.logout();
      }
    },
    connectSucces() {
      this.menuState = "play";
      this.connectData.code = "";
      this.playData.buttons[0].isActive = true;
      this.playData.buttons[1].isActive = false;
    }
  }
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>