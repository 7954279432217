import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import './assets/swiper.css'
import './assets/main.css'

// Import Font Awesome core
import { library } from '@fortawesome/fontawesome-svg-core';

// Import specific icons
import { faUser, faCoffee, faTimes, faCheck, faPlug, faUsers, faUsersSlash, faMicrophone, faMicrophoneSlash, faPhone, faEllipsisH, faFingerprint, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Import Font Awesome component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Tambahkan ikon ke library
library.add(faUser, faCoffee, faTimes, faCheck, faPlug, faUsers, faUsersSlash, faMicrophone, faMicrophoneSlash, faPhone, faEllipsisH, faFingerprint, faChevronLeft);

createApp(App).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
