<template>
  <PlayTogether />
</template>

<script>
import PlayTogether from './components/PlayTogether.vue';
export default {
  name: 'App',
  components: {
    PlayTogether
  }
}
</script>
